.wrapper {
  @apply flex flex-col align-middle justify-center h-64 text-center;

  & :global(h2) {
    @apply mb-4;
  }
}

.result {
  & :global(h2) {
    @apply font-sans text-2.5xl font-normal mb-10;
  }

  & :global(ul) {
    @apply grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4;
  }
}
