/* theme/hero */
.hero {
  @apply relative flex flex-col;
  & :global(.background) {
    @apply absolute top-0 left-0 z-0 flex justify-center w-full h-full overflow-hidden;
  }

  & :global(.inner-wrapper) {
    @apply mx-auto max-w-8xl z-10;

    width: calc(100% - 2rem);

    @media screen and (min-width: 768px) {
      width: calc(100% - 4rem);
    }
  }

  &:global(.fullwidth) {
    &:global(.is-centered .content) {
      @apply mx-auto text-center;
    }

    &:global(.hero-size-md .inner-wrapper) {
      min-height: 550px;
    }

    &:global(.hero-size-lg) {
      & :global(.inner-wrapper) {
        min-height: 680px;
      }

      &:global(.has-articles .inner-wrapper) {
        min-height: 550px;
      }
    }

    & :global(.inner-wrapper) {
      @apply flex items-center;
    }

    & :global(.text-effect) {
      &:global(.shadow-sm h1),
      &:global(.shadow-sm p) {
        text-shadow: rgba(0, 0, 0, 1) 1px 0 5px;
      }

      &:global(.shadow-lg h1),
      &:global(.shadow-lg p) {
        text-shadow: rgba(0, 0, 0, 1) 1px 0 10px;
      }

      &:global(.text-bg[data-block="core/heading"]) {
        @apply pb-2 sm:pb-0 md:pb-4;

        & h1 {
          @apply inline leading-[3.2rem] sm:leading-[3.8rem] lg:leading-[4.5rem] px-3 py-[0.01em];
          @apply box-decoration-clone;
        }
      }

      &:global(.text-bg[data-block="core/paragraph"]) {
        @apply pb-2 sm:pb-0 md:pb-4;

        & p {
          @apply inline leading-[2.2rem] sm:leading-[2.8rem] lg:leading-[2.6rem] px-3 py-[0.2em];
          @apply box-decoration-clone;
        }
      }

      &:global(.white p),
      &:global(.white h1) {
        @apply bg-white;
      }

      &:global(.black p),
      &:global(.black h1) {
        @apply bg-black-100;
      }

      &:global(.shadowed) {
        & p,
        & h1 {
          @apply bg-black-100/60;
        }
      }

      &:global(.shadow-lg) {
        @apply shadow-none;
      }

      &:global(.green p),
      &:global(.green h1) {
        @apply bg-good-green-100;
      }

      &:global(.blue p),
      &:global(.blue h1) {
        @apply bg-good-blue-100;
      }
    }
  }

  &:global(.bra-miljoval) {
    & :global(.inner-wrapper) {
      @apply flex flex-col-reverse lg:flex-row justify-between lg:items-center lg:space-x-10;
      @apply pb-6 lg:py-8;
    }

    &:global(.no-panel .inner-wrapper) {
      @apply pb-20;
    }

    & :global(.content) {
      @apply lg:w-[40%];

      & :global(h1) {
        @apply break-words;
      }
    }

    & :global(.hero-image) {
      @apply relative w-full lg:w-[60%];
    }

    & :global(.hero-image) {
      @apply aspect-w-16 aspect-h-9 lg:aspect-h-5 mb-8 lg:mb-0;
    }

    & :global(.hero-image) {
      @apply relative;
    }
  }

  & :global(.hero-breadcrumbs) {
    @apply pt-[70px] sm:pt-[100px] md:pt-[100px] mb-2.5 z-[2] h-[20px];

    width: calc(100% - 2rem);

    @media screen and (min-width: 768px) {
      width: calc(100% - 4rem);
    }
  }

  &:global(.has-top-menu .hero-breadcrumbs) {
    @apply md:pt-[140px];
  }

  & :global(.content) {
    @apply max-w-1xl;

    & > div {
      @apply p-0;
    }

    & h1 {
      @apply mb-5;
    }

    & div[data-block="core/button"] {
      @apply pt-6 lg:pt-0;
    }

    & p {
      @apply lg:mb-7;

      &:global(.is-style-object-tag) {
        @apply mb-0;
      }
    }
  }

  & :global(.panel-wrapper) {
    @apply z-10;
    background: linear-gradient(to bottom, transparent 50%, white 50%);
  }

  & :global(.articles-wrapper) {
    @apply mx-auto max-w-8xl;
    width: calc(100% - 2rem);

    @media screen and (min-width: 768px) {
      width: calc(100% - 4rem);
    }

    & :global(h3) {
      @apply pb-3;
    }
  }

  & :global(.search-wrapper) {
    @apply mx-auto max-w-8xl bg-white mt-9 pt-8 shadow text-center;
    width: calc(100% - 2rem);

    @media screen and (min-width: 768px) {
      width: calc(100% - 4rem);
    }

    & :global(h3) {
      @apply pb-3 px-4;
    }

    & :global(p) {
      @apply pb-5 px-4;
    }

    & :global(.type-select .adv-select) {
      @apply min-w-50;
    }
  }
}

.overlay {
  @apply top-0 absolute w-full h-full z-[1] bg-black-100;

  &:global(.opacity0) {
    @apply opacity-0;
  }

  &:global(.opacity10) {
    @apply opacity-10;
  }

  &:global(.opacity15) {
    @apply opacity-15;
  }

  &:global(.opacity20) {
    @apply opacity-20;
  }

  &:global(.opacity25) {
    @apply opacity-25;
  }

  &:global(.opacity30) {
    @apply opacity-30;
  }

  &:global(.opacity40) {
    @apply opacity-40;
  }

  &:global(.opacity50) {
    @apply opacity-50;
  }

  &:global(.opacity60) {
    @apply opacity-60;
  }

  &:global(.opacity70) {
    @apply opacity-70;
  }
}

.gradient {
  @apply top-0 absolute w-full h-full z-[2];
}

.videoOverlay {
  @apply object-cover w-full h-full xl:h-auto;
}

.mediaAuthor {
  @apply mt-4 font-sans text-sm leading-normal;
}

.articles {
  @apply grid w-full grid-cols-1 bg-white divide-y-2 shadow md:py-6 md:grid-cols-3 md:divide-y-0 md:divide-x-2 divide-black-5 text-black-100;
}

.article {
  @apply py-5 mx-5 md:mx-0 md:px-8 md:py-0;
}

.article:first-child {
  @apply md:pl-5;
}
