.blockGrid {
  @apply grid grid-cols-1 gap-8 lg:grid-cols-4 md:grid-cols-2;
}

.heading {
  @apply pb-8;
}

.category {
  @apply ml-2 font-normal underline;
}
