.card {
  @apply flex w-full border-b border-black-25 pb-4;

  & a {
    @apply flex w-full;
  }

  & :global(.no-image) {
    @apply w-12 h-12 md:w-45 md:h-45 flex-none bg-black-100/10;
  }

  & figure {
    @apply w-12 h-12 md:w-45 md:h-45 flex-none relative;

    &:global(.is-icon) {
      @apply bg-good-green-20 flex justify-center items-center;

      & img {
        @apply w-6 h-6 md:w-22 md:h-22;
      }
    }

    & img {
      transition: transform 1s cubic-bezier(0.395, 0.005, 0.19, 1);
    }
  }

  & :global(.content) {
    @apply pl-4;

    & h2 {
      @apply text-base font-sans font-medium md:text-xl pb-1.5;
    }
  }

  &:hover {
    & img {
      transform: scale(1.025);
    }
    & h3 {
      background-size: 100% 1px;
    }
  }

  & :global(.member-only-tag) {
    @apply inline-flex items-center py-1.5 px-2 mr-3 mb-2 bg-summer-green-50 rounded font-bold uppercase text-xs tracking-[1px];

    & svg {
      @apply inline-block mr-1.5;
    }
  }
}
