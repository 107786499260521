.card {
  @apply border rounded border-black-100/20 flex flex-col  bg-white;

  & :global(.inner-wrapper) {
    @apply mx-5 mt-5 mb-2.5 h-full;
  }

  & :global(.icon) {
    @apply w-17 h-17 p-2.5 rounded-lg bg-good-green-10 mb-3.6;
  }

  & :global(figure) {
    @apply relative w-full h-full;
  }

  & :global(h6) {
    @apply text-xs font-bold uppercase leading-4.5 mb-1.4;
  }

  & :global(h4) {
    @apply font-sans text-lg md:text-xl leading-6.5 mb-1.4 break-words;
  }

  & :global(.text) {
    @apply text-sm;
    & :global(span) {
      @apply block;
    }
  }

  & :global(.content) {
    @apply min-h-34;
  }

  & footer {
    @apply flex justify-between items-center;
    @apply border-t border-black-100/20 rounded-b;
    @apply h-12 pl-4 text-base font-bold;

    & :global(.square) {
      @apply w-12 h-12 flex items-center justify-center bg-good-green-50;
    }

    & svg {
      @apply w-[16px] h-auto;
    }
  }
}
