/* Includes all article specific styling */

.main {
  @apply bg-white pt-18 sm:pt-24;
  @apply text-lg lg:text-xl leading-8;

  &:global(.has-admin-bar) {
    @apply pt-30 sm:pt-36 md:pt-34 lg:pt-44;

    &:global(.has-top-menu) {
      @apply lg:pt-44;
    }
  }

  &:global(.has-top-menu) {
    @apply lg:pt-36;
  }

  & :global(div[data-block="breadcrumbs"]) {
    @apply mb-5;
  }

  & :global(.serif) {
    @apply font-libre;
  }

  & :global(article) {
    @apply relative;

    & :global(div[data-block="core/heading"] h2) {
      @apply text-2xl sm:text-2xl lg:text-2.5xl;
    }

    & h3,
    & h4,
    & h5,
    & h6 {
      @apply text-xl sm:text-1.5xl lg:text-2xl;
    }

    & :global(.object-tag) {
      @apply pb-6;
    }

    & :global(a:not(.no-underline):not(.btn)) {
      @apply underline;
    }
  }

  & :global(.is-style-article-tips) {
    @apply relative p-5 pl-8 overflow-hidden text-lg bg-white;
    @apply box-border leading-normal border border-black-100/20 rounded-md shadow-sm;
    &:before {
      @apply absolute top-0 left-0 block w-2 h-full bg-summer-green-100 content-[""];
    }
  }
}

.related-wrapper {
  @apply bg-good-blue-10;
  @apply pb-10 md:pb-15 lg:pb-20;

  &:global(.is-white) {
    @apply bg-white;
  }

  & :global(> .inner-wrapper) {
    @apply box-content pt-8 px-4 mx-auto mt-10 max-w-8xl md:mt-15 lg:mt-20 md:px-8;
  }
  & :global(.list) {
    @apply grid grid-cols-1 gap-7.5 md:gap-10 sm:grid-cols-2 sm:mb-8 lg:grid-cols-4 lg:mb-0;
  }
}

@media print {
  .main {
    @apply text-base pt-0;
  }

  .grecaptcha-badge {
    @apply hidden;
  }

  .hasSearch {
    @apply mt-0;
  }
}

@page {
  size: A4;
  /* margin: 11mm 17mm 17mm 17mm; */
}
