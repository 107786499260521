.result {
  &:global(.is-empty) {
    @apply flex flex-col align-middle justify-center min-h-96 text-center;
    @apply transition-height duration-200;
  }

  & :global(.view-grid) {
    @apply grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4;
    @apply mb-10;
  }

  &:global(.context-site .view-grid) {
    @apply grid-cols-1 gap-y-4 flex-none max-w-4xl mx-auto;
  }
}
