.wrapper {
  @apply z-20 relative pb-8;

  &:global(.has-image > header .breadcrumbs),
  &:global(.has-image > header .content) {
    @apply text-white;
  }

  &:global(.is-good-green > header) {
    @apply bg-good-green-10;
  }

  & > header {
    @apply relative flex flex-col items-center text-center bg-good-green-10;

    & figure img {
      filter: brightness(70%);
    }

    & :global(.content) {
      @apply pt-10 pb-24 z-10;

      & :global(span) {
        @apply text-sm font-bold font-sans tracking-wider uppercase block;
      }

      & :global(h1) {
      }

      & p {
        @apply pt-4 px-4 max-w-1xl mx-auto text-lg lg:text-xl;
      }
    }
  }

  & :global(.form-wrapper) {
    @apply box-content px-4 md:px-8 -mt-13 relative z-10;
    @apply max-w-8xl mx-auto;
    @apply w-[calc(100%-2rem)] md:w-[calc(100%-4rem)];

    & :global(.search-wrapper) {
      @apply bg-white shadow p-6 mb-8 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-12 gap-4;

      & > div {
        @apply col-span-1 xl:col-span-3;
      }

      & :global(.choices) {
        @apply w-full mt-4 lg:col-span-2 xl:col-span-12;

        & ul {
          @apply mt-2;
        }

        & li {
          @apply inline-block mr-2 mb-2 bg-good-green-20 rounded;
          @apply text-xs font-sans font-bold tracking-widest uppercase px-2 py-1;
          @apply cursor-pointer inline-flex justify-between items-center;

          & svg {
            @apply inline-block w-[8px] h-[8px] ml-1;
          }

          &:global(.clear-all) {
            @apply bg-transparent;
          }
        }
      }
      &:global(.context-projects) {
        & :global(.search) {
          @apply col-span-1 lg:col-span-2 xl:col-span-5;
        }

        & :global(.filter) {
          @apply col-span-1 xl:col-span-2;

          &:global(.action) {
            @apply xl:col-span-3;
          }
        }
      }

      &:global(.context-site) {
        & :global(.search) {
          @apply col-span-1 lg:col-span-2 xl:col-span-12;
        }
      }
    }
  }

  & :global(.sub-wrapper) {
    @apply md:flex md:justify-between md:items-center;

    &:global(.context-site) {
      @apply max-w-4xl mx-auto;
    }

    & h2 {
      @apply pb-4 md:pb-0 text-xl;
    }

    & :global(.settings-wrapper) {
      @apply grid grid-cols-2 gap-4;
      @apply md:flex md:justify-end md:space-x-4 md:gap-0;

      &:global(.settings-1) {
        @apply grid-cols-1;
      }
    }

    & :global(.settings-wrapper) {
      @apply md:flex-grow;

      &:global(.is-map-view .sort) {
        @apply hidden;
      }

      &:global(.is-map-view .view) {
        @apply col-span-2;
      }

      & > div {
        @apply md:max-w-[200px];
      }
    }
  }
}
