.blockGrid {
  @apply grid grid-cols-1 gap-10 sm:grid-cols-2;
}

.article {
  @apply flex flex-col w-2/3;
}

.loadMoreButton {
  @apply mx-auto w-full md:w-6/12 mt-13 flex justify-center items-center;

  & span {
    @apply px-2;
  }
}

.noMoreArticles {
  @apply w-full text-center p-4;
}
