.controller {
  @apply h-15 w-full rounded;
  @apply relative;

  &:global(.is-open) {
    @apply z-40;
  }

  &:global(.has-value > .checkboxes) {
    @apply border-black-50;
  }

  &:global(.is-empty > .checkboxes) {
    @apply opacity-50;
  }

  & :global(.checkboxes) {
    @apply absolute top-0 left-0 w-full bg-white border border-black-25 rounded;
    @apply bg-white;

    &:global(.has-shadow) {
      @apply shadow-md;
    }
  }

  & header {
    @apply flex flex-row items-center justify-between px-4 py-3 cursor-pointer;

    & :global(.current-choice) {
      @apply text-lg leading-none pointer-events-none text-left flex-grow;

      & span {
        @apply block pb-1 font-sans text-xs font-bold leading-none;
      }
    }

    & svg {
      @apply w-[11px] h-auto pointer-events-none;
    }

    & :global(.clear-filter) {
      @apply bg-good-green-100 w-5 h-5 rounded mr-3 cursor-pointer;
      @apply flex justify-center items-center;
      & svg {
        @apply w-[8px] h-auto text-white;
      }
    }
  }

  & :global(.open-filter) {
    @apply relative z-30 bg-white rounded-b overflow-hidden;

    & :global(.overlay) {
      @apply absolute w-full h-full z-40 pointer-events-none;
      &:after {
        @apply absolute bottom-0 left-0 h-2 rounded-b content-[""];
        @apply bg-white;
        @apply bg-gradient-to-b from-transparent to-white;
        width: calc(100% - 4px);
      }
    }

    & :global(ul) {
      @apply pl-4 overflow-y-scroll overflow-x-hidden max-h-[416px] relative;

      & li {
        @apply mb-3 text-base select-none;
      }

      &::-webkit-scrollbar {
        @apply w-1;
      }
      &::-webkit-scrollbar-track {
        @apply bg-black-25;
      }
      &::-webkit-scrollbar-thumb {
        @apply bg-black-100;
      }
      &::-webkit-scrollbar-thumb:hover {
        @apply bg-black-75;
      }
    }

    & :global(.button-wrapper) {
      @apply px-4 pb-3 mt-2;

      & :global(.button) {
        @apply w-full font-sans;
        &:global(.dim) {
          @apply opacity-20;
        }
      }
    }
  }

  & :global(.checkbox) {
    @apply flex items-start cursor-pointer;
    & :global(.square) {
      @apply flex-none w-6 h-6 border rounded flex items-center justify-center border-black-100/25;

      & svg {
        @apply fill-white;
      }
    }

    &:global(.is-child) {
      @apply ml-4;
    }

    & :global(.count) {
      @apply text-black-50;
    }

    &:global(.is-checked .square),
    &:global(.has-checked-children .square) {
      @apply bg-good-green-100 border-good-green-100;
    }

    & :global(.parent-dot) {
      @apply bg-black-100 w-[5px] h-[5px] rounded-full;
    }

    & :global(.label-wrapper) {
      @apply flex items-center justify-between w-full pr-4;
    }

    & :global(.label) {
      @apply pl-3 max-w-[85%];
    }
  }
}
