.card {
  & img {
    @apply image-has-hover-effect;
  }
}

.card.hovered {
  & img {
    @apply image-hover-effect;
  }

  & .headline {
    @apply text-hover-effect;
  }
}

.headline {
  @apply inline font-sans text-xl font-medium text-has-hover-effect;
}

.imageWrapper {
  @apply relative w-full h-0 mb-3.6 overflow-hidden pt-full;
}

.image {
  @apply absolute top-0 flex items-center justify-center w-full h-full bg-black-5 cursor-pointer;
}

.byLine {
  @apply mt-2.5 lg:mt-3 text-sm;
}

.image {
  @apply object-cover object-center w-full h-full;
}
