.card {
  @apply bg-white text-left;

  & a {
    @apply flex flex-col h-full;
  }

  &:hover {
    & img {
      @apply image-hover-effect;
    }
  }

  & :global(.type) {
    @apply text-xs font-bold font-sans tracking-wider uppercase block mb-1;
  }

  & h2 {
    @apply text-xl font-bold font-sans mb-4 line-clamp-3 overflow-hidden;
  }

  & ul {
    @apply text-sm mt-auto;

    & li {
      @apply line-clamp-1 overflow-hidden;
    }
  }

  & :global(.image-wrapper) {
    @apply relative;

    & :global(.icon) {
      @apply absolute z-10 top-4 left-4 w-10 h-10 flex items-center justify-center rounded-full bg-good-green-10;
      & svg {
        @apply w-6 h-6;
      }
    }
  }

  & figure {
    @apply relative aspect-w-4 aspect-h-3 overflow-hidden rounded-t bg-black-100/20;
  }

  & img {
    @apply image-has-hover-effect;
  }

  & :global(.content) {
    @apply p-4 border-l border-r border-black-100/20 flex flex-col flex-grow;
  }

  & footer {
    @apply flex justify-between items-center self-end w-full;
    @apply border border-black-100/20 rounded-b;
    @apply h-12 pl-4 text-base font-bold;

    & :global(.square) {
      @apply w-12 h-12 flex items-center justify-center bg-good-green-50;
    }

    & svg {
      @apply w-[16px] h-auto;
    }
  }
}
