.card {
  @apply box-content border rounded border-black-100/10 flex flex-col w-full h-full bg-white overflow-hidden;

  & :global(.heading) {
    @apply font-sans text-lg md:text-xl leading-6.5 mb-1.5 md:mb-1.7;
  }

  & footer {
    @apply flex justify-between h-12 items-center px-4;
    @apply text-base font-bold border-t border-black-100/10 relative;
    & :global(.icon-btn) {
      @apply absolute right-0 top-0 h-12 flex items-center w-12;
    }
  }
}

.icon {
  @apply p-2.5 rounded-lg bg-good-green-10 mb-3.6;
  width: 70px;
  height: 70px;
}
